import Image from 'next/image';
import { AddNoteText, NoteText } from './styled';
import { Box } from '@mui/material';
import { trackClickNotes } from 'componentes/ProductId/track';
import { NotebookPen } from 'lucide-react';
import { FormattedMessage } from 'react-intl';

function ProductNotesInCart({ line, setModalNotes, setLineForNotes }) {
  const handleClick = () => {
    setLineForNotes(line);
    setModalNotes(true);
    trackClickNotes(line.datos_producto.id);
  }

  return (
    <Box sx={{ display:'flex', width: '35%' }}>
        {line?.notes ? (
        <Box 
        onClick={handleClick} 
        sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', maxWidth: '100%'}}>
          <Box sx={{width:'16px', height: '16px'}}>
            <Image
            src='/imgs/notePencilGray.svg'
            alt='notes'
            width={16}
            height={16}
            />
          </Box>
            <NoteText>
              {line.notes}
            </NoteText>
        </Box>
        )
        :
        (
          <Box
          onClick={handleClick}
          sx={{ 
            display:'flex', alignItems: 'center', justifyContent:'flex-start', maxWidth:'100%'
          }}>
              <AddNoteText>
                <NotebookPen size={19} />
                <FormattedMessage
                id='addNotesId'
                defaultMessage='Add notes'
                />
              </AddNoteText>
          </Box>
        )}
    </Box>
  );
}

export default ProductNotesInCart;
