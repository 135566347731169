import styled, { keyframes }  from 'styled-components'

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

export const Container2 = styled.div`
display: flex;
flex-direction: column;
justify-content: flex-end;
align-items: center;
background-color:rgb(48, 54, 64, 0.7);
height: 100vh;
width: 100vw;
position: fixed;
top: 0;
z-index: 40;
max-width: 490px;
`

export const DetailBlock = styled.div`
min-height: 30vh;
height: auto;
width: 100vw;
background: #FFF;
position: relative;
animation: ${slideIn} 0.5s ease-in-out forwards;
padding: 16px 16px 24px 16px;
max-width: 490px;
`

export const ContainerModal = styled.div`
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
background-color:rgb(48, 54, 64, 0.7);
height: 100vh;
width: 100vw;
position: fixed;
top: 0;
z-index: 8;
max-width: 490px;
`

export const CloseModal = styled.div`
top: 24px;
left: 16px;
position: absolute;
z-index: 3;
`

export const Container = styled.div`
width: 90%;
height: 60%;
background: white;
`

export const AddNoteText = styled.div`
color: ${props => props.theme.secondaryDark};
font-size: 12px;
display: flex;
>svg{
  margin-right: 5px;
}
`

export const NoteText = styled.p`
color: ${props => props.theme.textSecondary};
font-family: sans-serif;
font-size: 12px;
font-style: normal;
line-height: 12px;
margin-left: 4px;
margin-top: 4px;
overflow: hidden;
white-space: nowrap;
text-overflow: ellipsis;
max-width: 80%;
`

export const TextLabel = styled.p`
color: #011D0E;
font-family: sans-serif;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: 22px; 
margin-bottom: 8px;
`

export const ContainerButton = styled.div`
    background-color: #FFF;
    width: 100%;
    padding: 24px 16px 32px;
    border-top: 1px solid #F2F3F3;
    max-width: 490px;
    display: flex;
    justify-content: center;
    box-shadow: 0px 4px 10px 0px rgba(14, 0, 55, 0.03);
`

export const Button = styled.div`
border-radius: 8px;
background: ${props => props.theme.primary};
height: 56px;
display: flex;
padding: 0px 20px;
justify-content: center;
align-items: center;
width: 100%;
`

export const ButtonText = styled.p`
color: #FFF;
text-align: center;
font-family: sans-serif;
font-size: 24px;
font-style: normal;
font-weight: 800;
line-height: 24px; 
`