import { useContext, useEffect, useState } from 'react';
import { Context } from 'context/Context';
import { useTheme } from 'styled-components';
import { 
  ContainerLine, 
  DetailsLine, 
  Title, 
  StyledImage, 
  ControlSection, 
  BlockDetails, 
  BlockPrice, 
  DeleteText, 
  Price, 
  UnitPrice,
  ContainerPriceSavings
} from './styled';
import AddToCartControl from 'componentes/AddToCartControl';
import formatNumber from 'services/formatNumber';
import { hasDiscount } from 'services/producthelpers';
import { currencies } from 'services/helpers';
import { trackRemoveFromCart } from './tracking';
import { FormattedMessage } from 'react-intl';
import CountDown from 'componentes/CountDown';
import calculateTimeDifferenceInSeconds from 'services/calculatedtimediscount';
import InfoPromo from './InfoPromo';
import ProductNotesInCart from 'componentes/ProductNotesInCart';
import { showProductNotes } from 'services/featureflags';
import { BadgeExpirationDate, ContainerExpirationDate, DateTextExpirationDate } from 'componentes/ProductId/styled';
import Image from 'next/image';

const ProductDetails = ({ line, preview, notAvailable, pais, showUnitPrice, router, sucursal }) => {
  return (
    <BlockDetails preview={!!preview} onClick={() => router.push(`/${sucursal}/${line.datos_producto.category_id}/${line.datos_producto.id}`)}>
      <StyledImage
        width={64}
        height={64}
        alt={line.nombre}
        src={line.imagen}
        notAvailable={!!notAvailable}
        style={{ height: 'auto' }}
      />
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Title notAvailable={!!notAvailable} preview={!!preview}>
          {preview && `x${line.cantidad} - `}{line.nombre}
        </Title>
        {showUnitPrice && (
          <UnitPrice>
            <span style={{ marginRight: '2px' }}>u/</span>
            {currencies[pais]}{line.datos_producto.price}
          </UnitPrice>
        )}
      </div>
    </BlockDetails>
  );
};

const PriceDetails = ({ line, theme, thisProductHasDiscount, preview, total, productTierDiscount, textTotalDiscount, pais, isDefaultTier }) => {
  const price = currencies[pais];
  return (
    <>
      <Price>{price}<span style={{ width: '2px' }} />{formatNumber(line.subtotal, pais)}</Price>
      {thisProductHasDiscount && !preview && (
        <>
          <Price before style={{ textDecoration: 'line-through' }}>
            {price}<span style={{ width: '2px' }} />{formatNumber(line.datos_producto.local_price ?? 0  * line.cantidad, pais)}
          </Price>
          {isDefaultTier && productTierDiscount ? (
            <Price productTierDiscount={productTierDiscount}>
              <span style={{
                fontSize: '10px',
                marginRight: '4px',
                fontWeight: '600',
                color: '#FFF',
                backgroundColor: theme.primary,
                padding: '0 4px'
              }}>
                PRIMERA COMPRA
              </span>
            </Price>
          ) : (
            <ContainerPriceSavings>
            <FormattedMessage
              id='textSaveId'
              defaultMessage="Save"                    
             />
            {price} 
            {formatNumber(total, pais)}
          </ContainerPriceSavings>
          )}
        </>
      )}
    </>
  );
};

function LineCart({ setLineForNotes, line, textTotalDiscount, preview, notAvailable, setShowSnackPopUp, refetch, setModal, setModalNotes, showUnitPrice }) {
  const theme = useTheme();
  const { handleBorrar, pais, currentInfoCountry, dataShop, isDefaultTier, router, sucursal } = useContext(Context);
  const [timeDifferenceInSeconds, setTimeDifferenceInSeconds] = useState(null);
  const [amountPromo, setAmountPromo] = useState(null);

  const productTierDiscount = line.datos_producto['product-discount']?.tier_id ?? null;
  const isShowProductNotes = showProductNotes(pais);
  const currency = currentInfoCountry?.currency_code;
  const thisProductHasDiscount = hasDiscount(line.datos_producto);

  const handleDelete = (e) => {
    handleBorrar(line.datos_producto.id, line.cantidad, line.subtotal, e);
    trackRemoveFromCart(currency, line.datos_producto.category?.name, line.datos_producto, line.cantidad);
  };

  useEffect(() => {
    if (line) {
      setAmountPromo(line.datos_producto.minimum_order_value);
      const endLineDate = line.datos_producto?.["product-discount"]?.["end_date"]
        ? new Date(line.datos_producto["product-discount"]["end_date"])
        : null;
      if (endLineDate) {
        setTimeDifferenceInSeconds(calculateTimeDifferenceInSeconds(endLineDate, pais));
      }
    }
  }, [line]);

  const price = Math.max(line.datos_producto.local_price ?? 0, line.datos_producto.price ?? 0);
  const total = price * line.cantidad - line.subtotal;

  const expiry_date = line.datos_producto['master-product']?.expiry_date
  ? new Intl.DateTimeFormat('es-MX').format(new Date(line.datos_producto['master-product'].expiry_date))
  : false;

  return (
    <ContainerLine>
      <DetailsLine>
        <ProductDetails
          {...{
            line,
            preview,
            notAvailable,
            pais,
            showUnitPrice,
            router,
            sucursal,
          }}
        />
        {!notAvailable ? (
          <BlockPrice>
            <PriceDetails
              {...{
                line,
                theme,
                thisProductHasDiscount,
                preview,
                total,
                productTierDiscount,
                textTotalDiscount,
                pais,
                isDefaultTier,
              }}
            />
            {
              expiry_date && (
                <ContainerExpirationDate
                paddingRight={"0px"}
                justifyContent={"flex-end"}
                marginBlock={"8px"}
              >
                <BadgeExpirationDate padding={"2px 4px"} fontSize={"10px"}>
                  <Image
                    src={"/imgs/calendar-x.svg"}
                    alt={"Calendar"}
                    width={12}
                    height={12}
                  />
                  {`Vence: `}
                  <DateTextExpirationDate
                    fontSize={"10px"}
                  >{expiry_date}</DateTextExpirationDate>
                </BadgeExpirationDate>
              </ContainerExpirationDate>
              )
            }
          </BlockPrice>
        ) : (
          <Price notAvailable={!!notAvailable}>
            <FormattedMessage
              id="labelNotAvailable"
              defaultMessage="Not available"
            />
          </Price>
        )}
      </DetailsLine>
      {amountPromo > 0 &&
        dataShop?.products_with_min_order_value === "enabled" && (
          <InfoPromo
            amount={amountPromo}
            quantity={line.cantidad}
            setModal={setModal}
          />
        )}
      {timeDifferenceInSeconds && (
        <CountDown
          setShowSnackPopUp={setShowSnackPopUp}
          initialTimeInSeconds={timeDifferenceInSeconds}
          item={line.datos_producto}
          cart
          refetch={refetch}
        />
      )}
      {!preview && (
        <ControlSection isShowProductNotes={isShowProductNotes}>
          {isShowProductNotes && (
            <ProductNotesInCart
              line={line}
              setModalNotes={setModalNotes}
              setLineForNotes={setLineForNotes}
            />
          )}
          <div style={{ display: "flex", alignItems: "center" }}>
            {line.cantidad > 1 && (
              <DeleteText onClick={handleDelete}>
                <FormattedMessage
                  id="textRemoveInCartId"
                  defaultMessage="Remove"
                />
              </DeleteText>
            )}
            <AddToCartControl
              product={line?.datos_producto}
              category={line?.categoria}
              maxWidth="144px"
              padding={0}
            />
          </div>
        </ControlSection>
      )}
    </ContainerLine>
  );
}

export default LineCart;
