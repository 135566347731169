import Image from 'next/image';
import { ContainerInfo, TitleInfo, TextInfo, ContainerFlex } from './styled';
import { FormattedMessage } from 'react-intl';
import { modalInfoMinimunOrderValue } from 'componentes/MinimumOrderValue/tracking';
import { useContext } from 'react';
import { Context } from 'context/Context';

export default function InfoPromo({amount, quantity, setModal}) {
    const { pais } = useContext(Context);

    const value = amount * quantity;
    return (
        <ContainerInfo>
            <ContainerFlex>
                <TitleInfo>
                    <FormattedMessage
                    id='infoPromo1'
                    defaultMessage='Promotion with minimum purchase amount'
                    />
                </TitleInfo> 
                <Image onClick={()=> {
                modalInfoMinimunOrderValue(pais, 'Line cart view');
                setModal(true);
                }} src='/imgs/information-circle.svg' alt='information' width={16} height={16} />
            </ContainerFlex>

            <TextInfo>
                <FormattedMessage
                    id='infoPromo2'
                    defaultMessage='Promotion valid on orders over ${value}.'
                    values={{value}}
                />
            </TextInfo>
        </ContainerInfo>
    )
}