import styled, { css }  from 'styled-components'
import Image from 'next/image';
import { greenButtons, yellowDiscount } from 'componentes/Styles/Global';

export const ContainerLine = styled.div`
width: 100%;
display: flex;
flex-direction: column;
justify-content: center;
align-items: flex-end;
border-bottom: 1px solid #E6E9E7;
padding: 8px 16px 12px;
position: relative;
`
export const DetailsLine = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
`
export const BlockDetails = styled.div`
display: flex;
justify-content: flex-start;
width: ${props => props.preview ? '75%' : '65%'};
`
export const BlockPrice = styled.div`
display: flex;
flex-direction: column;
padding: 0px 8px;
justify-content: space-between;
`
export const Price = styled.div`
font-family: 'Roboto';
font-style: normal;
font-weight: ${props => props.before ? '400' : props.saving ? '800' : '800'};;
font-size: ${props => props.before ? '12px' : props.saving ? '14px' : props.notAvailable ? '12px' : '16px'};
line-height: 16px;
display: flex;
justify-content: flex-end;
text-align: right;
color: ${props => props.before ? '#344A3E' : '#011D0E'};
background-color: ${props => props.saving && `${yellowDiscount}` };
background-color: ${props => props.productTierDiscount && `rgb(254, 72, 6)` };
margin-bottom: 8px;
`
export const Title = styled.div`
font-family: 'Roboto';
max-width:  ${props => props.preview ? '210px' : '160px'};
font-style: normal;
font-weight: 500;
font-size: 14px;
line-height: 18px;
color: #011D0E;
margin-left: 9px;
overflow: hidden;
text-overflow: ellipsis;
text-decoration: ${props => (props.notAvailable && 'line-through')};
opacity: ${props => (props.notAvailable ? '0.5' : '1')};
`

export const UnitPrice = styled.p`
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 12px;
line-height: 18px;
color: #011D0E;
margin-left: 9px;
display: flex;
align-items: center;
`

export const ControlSection = styled.div`
display: flex;
justify-content: ${props => props.isShowProductNotes ? 'space-between': 'flex-end'};
align-items: center;
width: 100%;
`
export const DeleteText = styled.div`
width: 52px;
height: 16px;
font-family: 'Roboto';
font-style: normal;
font-weight: 800;
font-size: 13px;
line-height: 16px;
display: flex;
align-items: center;
color: ${greenButtons};
margin-right: 3px;
`

export const StyledImage = styled(Image).withConfig({
    shouldForwardProp: (prop) => prop !== 'notAvailable',
  })`
    filter: ${(props) => (props.notAvailable ? 'grayscale(100%)' : 'none')};
    &:active {
      opacity: 0.3;
    }
  `;

export const Button = styled.div`
justify-content: ${props => props.center ? 'center' : 'space-between'};
box-sizing: border-box;
display: flex;
align-items: center;
padding: 0px 8px;
height: 40px;
width: ${props => props.maxWidth};
border: 1px solid #00904B;
border-radius: 6px;
&:active {
    opacity: ${props => props.active ? 0.3 : 1};
}
cursor: pointer;
`

export const TextButton = styled.p`
font-size: ${props => props.quantity ? '16px' : ' 14px'};
font-family: 'Roboto';
font-style: normal;
font-weight: 800;
line-height: 14px;
text-align: center;
color: #00904B;
`

export const ContainerInfo = styled.div`
border-radius: 8px;
background: #F7F8F7;
box-shadow: 0px 1px 3px 0px rgba(103, 98, 98, 0.05);
display: flex;
flex-direction: column;
padding: 8px 16px;
width: 100%;
margin: 8px 0px;
`;

export const TitleInfo = styled.p`
color: ${props => props.theme.textDefault};
font-family: sans-serif;
font-size: 14px;
font-style: normal;
font-weight: 600;
line-height: 12px;
display: flex;
align-items: center;
margin-right: 4px;
`;

export const TextInfo = styled.p`
color: ${props => props.theme.textDefault};
font-family: 'Roboto';
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: 16px;
`;

export const ContainerFlex = styled.div`
display: flex;
align-items: center;
`;

export const ContainerPriceSavings = styled.div`
display: flex;
justify-content: end;
width: 100%;
margin-top: 5px;
color: #FF3025;
font-family: Roboto;
font-size: 14px;
font-style: normal;
font-weight: 500;
line-height: 16px;
gap: 2px;
`;