import { defineMessages } from 'react-intl';

export default defineMessages({
  labelSaveId: {
    id: 'textSaveId',
    defaultMessage: 'Save',
  },
  labelTotalCart:{
    id: 'labelTotalCart',
    defaultMessage: 'Total'
  },
  labelSubtotalCart: {
    id: 'labelSubtotalCart',
    defaultMessage: 'Subtotal'
  },
  labelCategories: {
    id: 'categoriesTextIdLabel',
    defaultMessage: 'Categories'
  },
  textSearch: {
    id: 'textSearchId',
    defaultMessage: 'Search'
  },
  titleRepeatOrder: {
    id: 'titleRepeatOrder',
    defaultMessage: 'Repeat order',
  },
  labelEnterCode: {
    id: 'labelEnterCode',
    defaultMessage: 'Enter code...',
  },
  labelButtonMakeAnOrder :{
    id: 'labelButtonMakeAnOrder',
    defaultMessage: 'Make an order',
  },
  labelVerifiedPhoneCheckout:{
    id: 'labelVerifiedPhoneCheckout',
    defaultMessage: 'Verified cell phone',
  },
  subtitlelabelVerifiedPhoneCheckout:{
    id: 'labelVerifiedPhoneCheckout',
    defaultMessage: 'We will send the order confirmation to your whatsapp.',
  },
  labelApplyCouponCheckout:{
    id: 'labelApplyCouponCheckout',
    defaultMessage: 'Coupon applied! were discounted',
  },
  labelPurchaseCheckout: {
    id: 'labelPurchaseCheckout',
    defaultMessage: 'of your purchase',
  },
  errorPhoneNumberInvalid: {
    id: 'errorPhoneNumberInvalid',
    defaultMessage: 'The phone number is invalid',
  },
  errorPhoneNumberParse:{
    id: 'errorPhoneNumberParse',
    defaultMessage: 'Failed to parse phone number'
  },
  labelEnterName: {
    id: 'labelEnterName',
    defaultMessage: 'Enter your name',
  },
  labelEnterLastName:{
    id: 'labelEnterLastName',
    defaultMessage: 'Enter your last name'
  },
  labelBack:{
    id: 'labelback',
    defaultMessage: 'Back'
  }
});
