import styled, { css }  from 'styled-components'
import { primaryGreen } from '../Styles/Global'
import Image from 'next/image'

export const ContainerCart = styled.div`
position: fixed;
background-color: #FFF;
width: 100%;
bottom: 0;
align-items: flex-end;
padding: 14px 16px;
border-top: 1px solid #f4e6e6;
max-width: 490px;
z-index: 2;
`
export const ButtonCart = styled.div`
display: flex;
flex-direction: row;
justify-content: center;
align-items: center;
width: 100%;
height: auto;
padding: 8px;
background-color: ${props => props.color ? props.color : props.theme.primary};
border-radius: 24px;
cursor: pointer;
`

export const ContainerInsideButton = styled.div`
display: flex;
justify-content: ${props => props.repeatOrder ? 'center' : 'space-between'};
width: 100%;
padding: 0px 20px;
`
export const LeftBlock = styled.div`
display: flex;
justify-content: 'flex-start;
`
export const ImageCart = styled(Image)`
display: flex;
`

export const CartText = styled.div`
font-family: 'Roboto';
font-style: normal;
font-weight: 800;
font-size: 18px;
display: flex;
align-items: center;
color: ${props => props.theme.white};
margin-left: 10px;
`
export const PriceText = styled.div`
font-family: 'Roboto';
font-style: normal;
font-weight: 800;
font-size: 18px;
line-height: 20px;
display: flex;
align-items: center;
text-align: right;
color: ${props => props.theme.white};
`
export const TextButton = styled.p`
font-size: 18px;
color: ${primaryGreen};
font-weight: 600;
`