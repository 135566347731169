import styles from 'styles/FloatCart.module.scss';
import formatNumber from 'services/formatNumber';
import { Context } from 'context/Context';
import { useContext } from 'react';
import { currencies } from 'services/helpers'
import { ContainerCart, ButtonCart, ContainerInsideButton, LeftBlock, ImageCart, CartText, PriceText } from './styled'
import MinimumOrderValue from 'componentes/MinimumOrderValue';


function CartBar({ text, handleClick, total, color, changeView,
  handleChangeParaMayores, mostrarParaMayores, disclaimerMayores, repeatOrder, minimumOrderValue }) {
  const { spinner, pais } = useContext(Context);
  return (
    <ContainerCart mostrarParaMayores={mostrarParaMayores}>
            {
              mostrarParaMayores &&
              <div style={{display: 'flex', marginBottom: '10px', paddingLeft:'28px'}}>
                <input
                  type='checkbox'
                  id='customSwitch7'
                  defaultChecked={false}
                  style={{ width: '20px', height:'20px' }}
                  name='disclaimerMayores'
                  onChange={handleChangeParaMayores}
                  checked={disclaimerMayores}
                />
                <label
                  htmlFor='customSwitch7' 
                  style={{marginLeft:'7px', fontFamily:'sans-serif', color:'#EF7C30', fontWeight:'700', fontSize:'14px'}}
                >
                 SOY MAYOR DE 18 AÑOS
                </label>
              </div>
            }
      {
        minimumOrderValue > 0 &&
        <MinimumOrderValue block/>
      }
      <ButtonCart
        color={color}
        onClick={handleClick}
      >
          <ContainerInsideButton 
            repeatOrder={repeatOrder ? true : false}
          >
            <LeftBlock style={{display: 'flex', justifyContent: 'space-between'}}>
              {
                !changeView &&
                <ImageCart src={repeatOrder ? '/imgs/repeat.svg' : '/imgs/cartShop.svg'} width={repeatOrder ? 18 : 24} height={repeatOrder ? 18 : 24} />
              }
              <CartText>{text}</CartText>
            </LeftBlock>
            {total || total === 0 ? (
              <PriceText className={styles.price}>{currencies[pais]}<span style={{width: '2px'}} />{total}</PriceText>
            ) : (
              <p style={{ width: repeatOrder ? '0' : '40px' }}></p>
            )}
          </ContainerInsideButton>
      </ButtonCart>
    </ContainerCart>
  );
}
export default CartBar;
