import { track } from 'services/gtm';
import { totalDiscountNumber } from 'services/producthelpers';

export function trackRemoveFromCart(currency, categoryName, product, quantity) {
  track('remove_from_cart', {
    ecommerce: {
      currency: currency,
      value: product.price,
      items: [
        {
          item_name: product.name,
          item_id: product.id,
          price: product.price,
          discount: totalDiscountNumber(product),
          currency: currency,
          quantity: quantity,
          item_category: categoryName,
          item_category2: product.category_id,
        },
      ],
    },
  });
}
